import "./AnmeldeForm.scss"
import React, {useState} from "react";
import "react-widgets/scss/styles.scss";
import Multiselect from "react-widgets/Multiselect";
import SelectWithErrorContainer from "./SelectWithErrorContainer";
import InputWithErrorContainer from "./InputWithErrorContainer";

function asStandardString(str){
    if(!str){
        return "";
    }
    return str;
}
function isValidIBAN(iban) {
    // Remove spaces and convert to uppercase
    iban = iban.replace(/\s/g, '').toUpperCase();

    // Check if the IBAN length is valid
    if (iban.length < 15 || iban.length > 34) {
        return false;
    }

    // Ensure the IBAN consists only of digits and uppercase letters
    if (!/^[0-9A-Z]+$/.test(iban)) {
        return false;
    }

    // Move the first four characters to the end
    iban = iban.substring(4) + iban.substring(0, 4);

    // Replace letters with digits
    var digits = '';
    for (var i = 0; i < iban.length; i++) {
        var char = iban.charAt(i);
        if (char >= 'A' && char <= 'Z') {
            digits += char.charCodeAt(0) - 55;
        } else {
            digits += char;
        }
    }

    // Perform the modulo operation
    var remainder = 0;
    for (var i = 0; i < digits.length; i++) {
        remainder = (remainder + digits.charAt(i)) % 97;
    }

    return remainder === 1;
}

function formatDate(dateStr) {
    let date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1, and pad with '0' if needed.
    const day = String(date.getDate()).padStart(2, '0'); // Pad with '0' if needed.

    return `${year}-${month}-${day}`;
}

function isDateValid(dateString) {
    // Attempt to create a date object from the provided string
    const dateObject = new Date(dateString);

    // Check if the date object is valid
    // A date object is invalid if it represents an invalid date (e.g., February 30th) or if the input string is not in a valid date format.
    // So, we check both conditions.
    return !isNaN(dateObject) && dateObject.toString() !== 'Invalid Date';
}

function calculateAbtChange(value) {
    for (let i = value.length - 1; i >= 0; i--) {
        const newEle = value[i];
        if (newEle.replaces) {
            for (let rep of newEle.replaces) {
                for (let ii = i - 1; ii >= 0; ii--) {
                    const oldEle = value[ii];
                    if (oldEle.id === rep) {
                        value.splice(ii, 1);
                    }
                }
            }
        }
    }
}


export default () => {
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState([]);
    const [fitnessWarning, setFitnessWarning] = useState(false);
    const [erfahren, setErfahren] = useState('');

    const [fremdzahler, setFremdzahler] = useState(false);
    const [acceptErziehung, setAcceptErziehung] = useState(false);
    const [acceptSepa, setAcceptSepa] = useState(false);
    const [acceptDatenschutz, setAcceptDatenschutz] = useState(false);
    const [acceptSatzung, setAcceptSatzung] = useState(false);

    const [state] = useState(() => {
        return {
            fieldErrors: {},
            getValue: (fieldName, targetOb) => {
                if (targetOb) {
                    return targetOb[fieldName] || "";
                }
                return state[fieldName];
            },
            onChange: (fieldName, value, targetOb) => {
                if (targetOb) {
                    targetOb[fieldName] = value;
                    triggerFamilyRender();
                } else {
                    state[fieldName] = value;
                    triggerFamilyRender();
                }
            }
        }
    });

    /*const [bic, setBic] = useState('');
    const [mandate, setMandate] = useState('');*/

    const [abt, setAbt] = useState();

    const [selectedMembership, setSelectedMembership] = useState('einzelmitgliedschaft');

    const isAfterSept = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // getMonth() returns a zero-based index

        // Months from September (index 8) to December (index 11)
        if (currentMonth >= 8) {
            return true; // Current date is after September
        } else {
            return false; // Current date is before September
        }
    };
    const ContainsUmlaute = (string) => {
        return /[äöüÄÖÜß]/.test(string);
    }
    const [abteilungen] = useState(() => {
        return [{
            id: "bal",
            name: 'Ballett 12,-€',
            sparte: "Ballett",
            price: 12
        }, {
            id: "aqua",
            name: 'AquaSport- Schwimmen 3,-€ / Familie 7,-€',
            sparte: "Schwimmen/Aqua Sports Fun",
            replaces: ['aquaSauna'],
            price: 3,
            priceFamily: 7,
        }, {
            id: "aquaSauna",
            replaces: ['aqua'],
            sparte: "Sauna inkl Schwimmen",
            name: 'AquaSport- Sauna + Schwimmen  10,-€ / Familie 20,-€',
            price: 10,
            priceFamily: 20,
        }, {
            id: "fitness",
            name: 'Fitness-Studio 30,-€',
            sparte: "Fitness-Studio",
            price: 30,
            priceAufnahme: 20,
            halfPriceAfter15: true,
            sperr: true
        }, {
            id: "golf",
            name: 'Golf 60,-€ / Jugendliche 30,-€' + (isAfterSept() ? ", ab Sept. halber Beitrag" : ""),
            sparte: "Golf",
            price: 60,
            halfAfterSept: true,
            priceJugendlich: 30,
            priceType: "Saisonbeitrag"
        }, {
            id: "Tennis",
            name: 'Tennis 90,-€ / Kinder 45,-€' + (isAfterSept() ? ", ab Sept. halber Beitrag" : ""),
            sparte: "Tennis",
            price: 90,
            halfAfterSept: true,
            priceType: "Saisonbeitrag",
            priceKind: 45
        }, {
            id: "Klettern",
            name: 'Klettern 90,-€ / Jugendliche 60,-€',
            sparte: "Klettern",
            price: 90,
            priceType: "Saisonbeitrag",
            priceJugendlich: 60
        }, {
            id: "Badminton",
            name: 'Badminton',
            sparte: "Badminton",
        },
            {
                id: "Ballsport",
                name: 'Ballsport',
                sparte: "Ballsport",
            },
            /*{
                id: "Bildung und Teilhabe  Kinder",
                name: 'Bildung und Teilhabe  Kinder',
                sparte: "Bildung und Teilhabe  Kinder",
            },*/
            {
                id: "Tischtennis",
                name: 'Tischtennis',
                sparte: "Tischtennis"
            },
            {
                id: "Volleyball",
                name: 'Volleyball',
                sparte: "Volleyball"
            }, {
                id: "Capoeira",
                name: 'Capoeira',
                sparte: "Capoeira"
            }, {
                id: "Dance",
                name: 'Dance',
                sparte: "Dance"
            }, {
                id: "Fitness & Workout",
                name: 'Fitness & Workout',
                sparte: "Fitness & Workout"
            }, {
                id: "Gesundheit",
                name: 'Gesundheit',
                sparte: "Gesundheit"
            },
            {
                id: "Tanzen",
                name: 'Tanzen - Standard',
                sparte: "Tanzen - Standard"
            }, {
                id: "Herzsport",
                name: 'Herzsport',
                sparte: "Herzsport"
            }, {
                id: "Judo",
                name: 'Judo',
                sparte: "Judo"
            },
            {
                id: "Kampfsport",
                name: 'Kampfsport',
                sparte: "Kampfsport"
            },/* {
                id: "Kindergarten Kinderzeit",
                name: 'Kindergarten Kinderzeit',
                sparte: "Kindergarten Kinderzeit"
            },*/ {
                id: "Kindersport",
                name: 'Kindersport',
                sparte: "Kindersport"
            }, {
                id: "Outdoor",
                name: 'Outdoor',
                sparte: "Outdoor"
            }, {
                id: "Schwanger & Babysport",
                name: 'Schwanger & Babysport',
                sparte: "Schwanger & Babysport"
            }, {
                id: "Seniorensport",
                name: 'Seniorensport',
                sparte: "Seniorensport"
            }, {
                id: "Yoga & Pilates",
                name: 'Yoga & Pilates',
                sparte: "Yoga & Pilates"
            }
        ]
    });
    const [familyRenderId, setFamilyRenderId] = useState(1);
    const triggerFamilyRender = () => {
        setFamilyRenderId((v) => {
            return v + 1;
        })
    };
    const [familyMembers] = useState(() => {
        return [{}, {}]
    });
    const handleMembershipChange = (event) => {
        const v = event.target.value;
        if (v === "familienmitgliedschaft") {
            while (familyMembers.length < 2) {
                familyMembers.push({});
            }
        } else if (v === "elternkind") {
            while (familyMembers.length < 1) {
                familyMembers.push({});
            }
            while (familyMembers.length > 1) {
                familyMembers.splice(1, 1);
            }
        }

        setSelectedMembership(v);

    };

    const familyMode = selectedMembership === 'familienmitgliedschaft';
    const familyElternKindMode = selectedMembership === 'elternkind';
    const expectedPrices = [];

    let anmeldeArtMain = "Neu";
    let anmeldeArtFamily = "FamilieBatch";

    if (familyMode) {
        anmeldeArtMain = "FamilieBatch";
        anmeldeArtFamily = "FamilieBatch";
    }

    if (familyElternKindMode) {
        anmeldeArtMain = "ElternteilUndKind";
        anmeldeArtFamily = "ElternteilUndKind";
    }
    const calculateAge = (birthdate) => {
        const now = new Date();
        const diff = now - new Date(birthdate);
        return Math.floor(diff / (365 * 24 * 60 * 60 * 1000));
    }
    let age = 20;
    let geburtstag = state.getValue("geburtstag");
    if (geburtstag) {
        age = calculateAge(geburtstag);
    }
    const isMainKindOrSenior = age < 18 || age >= 66;
    const isMainKind = age < 18;

    console.log("isMainKind", age, isMainKind);
    let monthlyPrice = 25;
    let monthlyName = "";
    if (familyElternKindMode) {
        monthlyPrice = 12 + 9;
        monthlyName = "Monatsbeitrag Eltern & Kind";
    } else if (familyMode) {
        monthlyPrice = 25;
        monthlyName = "Monatsbeitrag Familie";
    } else {
        if (!isMainKindOrSenior) {
            monthlyPrice = 12;
            monthlyName = "Monatsbeitrag Erwachsene";
        } else {
            monthlyPrice = 9;
            monthlyName = "Monatsbeitrag Kinder ab 18 / Senioren ab 65";
        }

    }
    expectedPrices.push({
        name: "Aufnahmegebühr",
        price: monthlyPrice,
        amount: undefined,
        monthly: false
    });
    expectedPrices.push({
        name: monthlyName,
        price: monthlyPrice,
        amount: undefined,
        monthly: true
    });
    let existingAbt = {};
    const isAbtFamilyMode = (a) => {
        return familyMode && existingAbt[a.id] > 2;

    }
    const calculateAbtCost = (a, age, costPerAbtId) => {
        let actualFamilyMode = isAbtFamilyMode(a);
        let price = undefined;
        let isKind = "";
        if (a.priceAufnahme) {
            if (!costPerAbtId[a.id + "_aufnahme"]) {
                costPerAbtId[a.id + "_aufnahme"] = {
                    abteilung: a,
                    amount: 0,
                    aufnahme: a.priceAufnahme
                    //extraText: isKind ? " Kinder" : ""
                };
            }
            costPerAbtId[a.id + "_aufnahme"].amount++;
        }
        if ((!a.priceFamily || !actualFamilyMode) && a.price) {
            price = a.price;
        }
        if (a.priceFamily && actualFamilyMode) {
            price = a.priceFamily;
        }
        if (a.priceKind) {
            if (age < 18 /*|| age >= 66*/) {
                price = a.priceKind;
                isKind = "_kind";
            }
        }
        if (a.priceJugendlich) {
            if (age < 18 /*|| age >= 66*/) {
                price = a.priceJugendlich;
                isKind = "_kind";
            }
        }
        if (a.halfAfterSept) {
            if(isAfterSept()){
                price = Math.round(price / 2);
            }
        }
        //console.log("abtpreis",a.id,actualFamilyMode,existingAbt[a.id],a);
        if (price) {
            if (!costPerAbtId[a.id + isKind]) {
                costPerAbtId[a.id + isKind] = {
                    abteilung: a,
                    amount: 0,
                    extraText: isKind ? " Kinder" : ""
                }
            }
            if (a.priceFamily && actualFamilyMode) {
                costPerAbtId[a.id + isKind].amount = 1;
            } else {
                costPerAbtId[a.id + isKind].amount++;
            }
            costPerAbtId[a.id + isKind].price = price;
        }
    }

    if (abt && abt.length) {
        abt.forEach((a) => {
            if (!existingAbt[a.id]) {
                existingAbt[a.id] = 0;
            }
            existingAbt[a.id]++;
        });
    }
    if (familyMembers) {
        familyMembers.forEach((fm) => {
            let abt = fm.abt;
            if (abt && abt.length) {
                abt.forEach((a) => {
                    if (!existingAbt[a.id]) {
                        existingAbt[a.id] = 0;
                    }
                    existingAbt[a.id]++;
                });
            }
        });
    }
    console.log("existingAbt", abt);

    let costPerAbtId = {};
    if (abt && abt.length) {
        abt.forEach((a) => {
            calculateAbtCost(a, age, costPerAbtId);
        });
    }
    if (familyMembers) {
        familyMembers.forEach((fm) => {
            let age = 20;
            age = calculateAge(fm.geburtstag);
            fm.age = age;
            let abt = fm.abt;
            if (abt && abt.length) {
                abt.forEach((a) => {
                    calculateAbtCost(a, age, costPerAbtId);
                });
            }
        })
    }
    for (let costPerAbtIdKey in costPerAbtId) {
        const c = costPerAbtId[costPerAbtIdKey];

        if (c.aufnahme) {
            expectedPrices.push({
                name: c.abteilung.sparte + (c.extraText ? (" " + c.extraText) : ""),
                price: c.aufnahme,
                //priceKind: c.priceKind,
                amount: c.amount,
                priceType: c.abteilung.priceType,
                monthly: false
            });
        } else {
            expectedPrices.push({
                name: c.abteilung.sparte + (c.extraText ? (" " + c.extraText) : ""),
                price: c.price,
                //priceKind: c.priceKind,
                amount: c.amount,
                priceType: c.abteilung.priceType,
                monthly: true
            });
        }
    }
    let overallSum = 0;
    let overallSumOnce = 0;
    let specialOverallSums = {};
    let specialOverallSumsList = [];
    expectedPrices.forEach((ep) => {
        let sum = ep.price * ep.amount;
        if (!ep.amount) {
            sum = ep.price;
        }
        ep.sum = sum;
        if (ep.priceType) {
            if (!specialOverallSums[ep.priceType]) {
                specialOverallSums[ep.priceType] = 0;
                specialOverallSumsList.push(ep.priceType);
            }
            specialOverallSums[ep.priceType] += sum;
            overallSumOnce+=sum;
        } else {
            if (ep.monthly) {
                overallSum += sum;
            } else {
                overallSumOnce += sum;
            }
        }
    });

    let month = new Date().getMonth();
    month = (month % 3);

    let priceMonths = 3-month;


    let firstBill = overallSumOnce+priceMonths*overallSum;

    if(new Date().getDate()>=15){
        // nach 15.
        firstBill-=monthlyPrice;
        expectedPrices.push({
            name: "Rabatt Vereinsbeitrag nach 15.",
            price: monthlyPrice,
            amount: undefined,
            monthly: false,
            sum:-monthlyPrice
        });
    }


    const getNormalAgeChangedPrice=(costInfo,geburstag)=>{

        if(costInfo.priceKind||costInfo.priceJugendlich){
            age = calculateAge(geburstag);
            if(age <18){
                return costInfo.priceKind|costInfo.priceJugendlich;
            }
        }
        return costInfo.price;
    };
    let shouldFitnessWarn = false;
    const anmeldenClick = () => {
        let errors = [];
        let requiredText = " muss ausgefüllt werden";
        let noUmlaute = " darf keine Umlaute enthalten";
        if (!acceptSepa) {
            errors.push("SEPA Vereinbarung muss zugestimmt werden");
        }
        if (!acceptDatenschutz) {
            errors.push("Datenschutzvereinbarung muss zugestimmt werden");
        }
        if (!acceptSatzung) {
            errors.push("Vereinssatzung muss zugestimmt werden");
        }
        if (!acceptErziehung && isMainKind) {
            errors.push("Erziehungsberechtigte muss der Anmeldung zustimmen");
        }

        const titel = state.getValue("titel");
        const gutschein = state.getValue("gutschein");

        const vorname = state.getValue("vorname");
        const addNamedError = (name, text, targetOb) => {
            if (targetOb) {
                if (!targetOb.fieldErrors) {
                    targetOb.fieldErrors = {};
                }
                targetOb.fieldErrors[name] = text;
            } else {
                state.fieldErrors[name] = text;
            }
            errors.push(text);
        }
        if (!vorname?.length) {
            addNamedError("vorname", "Vorname" + requiredText)
        } else {
            if (ContainsUmlaute(vorname)) {
                addNamedError("vorname", "Vorname" + noUmlaute);
            }
        }
        const nachname = state.getValue("nachname");
        if (!nachname?.length) {
            addNamedError("nachname", "Nachname" + requiredText)
        } else {
            if (ContainsUmlaute(nachname)) {
                addNamedError("nachname", "Nachname" + noUmlaute);
            }
        }
        const email = state.getValue("email");
        if (!email?.length) {
            addNamedError("email", "Email" + requiredText)
        }
        const geschlecht = state.getValue("geschlecht");
        if (!geschlecht?.length) {
            addNamedError("geschlecht", "Geschlecht" + requiredText)
        }
        const geburtstag = state.getValue("geburtstag");
        if (!geburtstag?.length) {
            addNamedError("geburtstag", "Geburtstag" + requiredText)
        } else {
            if (!isDateValid(geburtstag)) {
                addNamedError("geburtstag", "Geburtstag ist ungültig");
            }
        }
        const strasse = state.getValue("strasse");
        if (!strasse?.length) {
            addNamedError("strasse", "Strasse" + requiredText)
        }
        const plz = state.getValue("plz");
        if (!plz?.length) {
            addNamedError("plz", "Plz" + requiredText)
        }
        const ort = state.getValue("ort");
        if (!ort?.length) {
            addNamedError("ort", "Ort" + requiredText)
        }
        const telefon = state.getValue("telefon");
        if (!telefon?.length) {
            addNamedError("telefon", "Telefon" + requiredText)
        }
        const iban = state.getValue("iban");
        if (!iban?.length) {
            addNamedError("iban", "IBAN" + requiredText)
        } else {
            if (!isValidIBAN(iban)) {
                addNamedError("iban", "IBAN ist ungültig")
            } else {
                if (iban.toLowerCase().indexOf("de") === -1) {
                    addNamedError("iban", "nur deutsche IBAN sind erlaubt")
                }
            }
        }
        const fzGeschlecht = state.getValue("fzGeschlecht");
        const fzVorname = state.getValue("fzVorname");
        const fzNachname = state.getValue("fzNachname");
        const fzPlz = state.getValue("fzPlz");
        const fzStrasse = state.getValue("fzStrasse");
        const fzOrt = state.getValue("fzOrt");
        if (fremdzahler || isMainKind) {
            if (!fzVorname?.length) {
                addNamedError("fzVorname", "Fremdzahler Vorname" + requiredText)
            } else {
                if (ContainsUmlaute(fzVorname)) {
                    addNamedError("fzVorname", "Fremdzahler Vorname" + noUmlaute);
                }
            }
            if (!fzNachname?.length) {
                addNamedError("fzNachname", "Fremdzahler Nachname" + requiredText)
            } else {
                if (ContainsUmlaute(fzNachname)) {
                    addNamedError("fzNachname", "Fremdzahler Nachname" + noUmlaute);
                }
            }
            if (!fzGeschlecht?.length) {
                addNamedError("fzGeschlecht", "Fremdzahler Geschlecht" + requiredText)
            }
            if (!fzPlz?.length) {
                addNamedError("fzPlz", "Fremdzahler Plz" + requiredText)
            }
            if (!fzStrasse?.length) {
                addNamedError("fzStrasse", "Fremdzahler Strasse" + requiredText)
            }
            if (!fzOrt?.length) {
                addNamedError("fzOrt", "Fremdzahler Ort" + requiredText)
            }
        }
        let familienBatchId = "";
        let familienMemberCount = 0;

        let sparten = "    <Sparten>\n";
        if (abt && abt.length) {
            abt.forEach((abtEntry) => {
                sparten += "      <Sparte>" + abtEntry.sparte + "</Sparte>\n";
                if (abtEntry.id === "fitness") {
                    if (isMainKind) {
                        shouldFitnessWarn = true;
                    }
                }
            });
        }else{
            addNamedError("abteilung", "Bitte Abteilungen wählen");
        }
        sparten += "    </Sparten>\n";

        let fremdZahlerTxt = "";

        if (fremdzahler) {
            fremdZahlerTxt = `    <KontoinhaberGeschlecht>${fzGeschlecht}</KontoinhaberGeschlecht>
    <KontoinhaberName>${fzNachname}</KontoinhaberName>
    <KontoinhaberVorname>${fzVorname}</KontoinhaberVorname>
    <KontoinhaberStrasse>${fzStrasse}</KontoinhaberStrasse>
    <KontoinhaberCo></KontoinhaberCo>
    <KontoinhaberLkz>D</KontoinhaberLkz>
    <KontoinhaberPlz>${fzPlz}</KontoinhaberPlz>
    <KontoinhaberOrt>${fzOrt}</KontoinhaberOrt>
    <KontoinhaberEmail>${email}</KontoinhaberEmail>`;
        }


        let familyMembersTxt = "";
        if (familyMembers?.length && (familyMode || familyElternKindMode)) {
            familienMemberCount = familyMembers.length + 1;
            familienBatchId = "_FAMILY_BATCH_";
            familyMembers.forEach((member) => {
                console.log("member", member);
                if (!member.vorname?.length) {
                    addNamedError("vorname", "Familienmitglied: Vorname" + requiredText, member);
                } else {
                    if (ContainsUmlaute(member.vorname)) {
                        addNamedError("vorname", "Familienmitglied: Vorname" + noUmlaute, member);
                    }
                }
                if (!member.nachname?.length) {
                    addNamedError("nachname", "Familienmitglied: Nachname" + requiredText, member);
                } else {
                    if (ContainsUmlaute(member.nachname)) {
                        addNamedError("nachname", "Familienmitglied: Nachname" + noUmlaute, member);
                    }
                }
                if (!member.geschlecht?.length) {
                    addNamedError("geschlecht", "Familienmitglied: Geschlecht" + requiredText, member);
                }
                if (!member.geburtstag?.length) {
                    addNamedError("geburtstag", "Familienmitglied: Geburtstag" + requiredText, member);
                } else {
                    if (!isDateValid(member.geburtstag)) {
                        addNamedError("geburtstag", "Familienmitglied: Geburtstag ist ungültig" + requiredText, member);
                    }
                }
                let familySparten = "    <Sparten>\n";
                if (member.abt && member.abt.length) {
                    member.abt.forEach((abtEntry) => {
                        familySparten += "      <Sparte>" + abtEntry.sparte + "</Sparte>\n";
                        if (abtEntry.id === "fitness") {
                            if (member.age < 18) {
                                shouldFitnessWarn = true;
                            }
                        }

                    });
                }else{
                        addNamedError("abteilungFamily", "Familien Mitglied Abteilung fehlt");
                }
                familySparten += "    </Sparten>\n";

                familyMembersTxt += `
  <Anmeldung>
    <AnmeldeArt>${anmeldeArtFamily}</AnmeldeArt>
    <AnzahlFamMitglieder>${familienMemberCount}</AnzahlFamMitglieder>
    <FamilienBatchID>${familienBatchId}</FamilienBatchID>
    <Titel>${asStandardString(member.titel)}</Titel>
    <Name>${member.nachname}</Name>
    <Vorname>${member.vorname}</Vorname>
    <Email>${email}</Email>
    <Geburtstag>${formatDate(member.geburtstag)}</Geburtstag>
    <Geschlecht>${asStandardString(member.geschlecht?.toUpperCase())}</Geschlecht>
    <Strasse>${strasse}</Strasse>
    <Co></Co>
    <Plz>${plz}</Plz>
    <Ort>${ort}</Ort>
    <Telefon>${telefon}</Telefon>
    <Iban>${iban}</Iban>
    ${fremdZahlerTxt}
    ${familySparten}
    <Erfahren>${asStandardString(erfahren)}</Erfahren>
    <GutscheinCode>${asStandardString(gutschein)}</GutscheinCode>
  </Anmeldung>
`;
            });
        }
        let xmlText = `<OnlineAnmeldung xsi:noNamespaceSchemaLocation="../../../XML/MitgliederOnlineData_Bornheim.xsd" 
                    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <Anmeldung>
    <AnmeldeArt>${anmeldeArtMain}</AnmeldeArt>
    <AnzahlFamMitglieder>${familienMemberCount}</AnzahlFamMitglieder>
    <FamilienBatchID>${familienBatchId}</FamilienBatchID>
    <Titel>${asStandardString(titel)}</Titel>
    <Name>${asStandardString(nachname)}</Name>
    <Vorname>${asStandardString(vorname)}</Vorname>
    <Email>${asStandardString(email)}</Email>
    <Geburtstag>${formatDate(geburtstag)}</Geburtstag>
    <Geschlecht>${asStandardString(geschlecht?.toUpperCase())}</Geschlecht>
    <Strasse>${strasse}</Strasse>
    <Co></Co>
    <Plz>${plz}</Plz>
    <Ort>${ort}</Ort>
    <Telefon>${telefon}</Telefon>
    <Iban>${iban}</Iban>
    ${fremdZahlerTxt}
    ${sparten}
    <Erfahren>${asStandardString(erfahren)}</Erfahren>
     <GutscheinCode>${asStandardString(gutschein)}</GutscheinCode>
  </Anmeldung>
${familyMembersTxt}
</OnlineAnmeldung>`;


        console.log("xmlText", xmlText);
        setError(errors);
        if (!errors.length) {
            (async () => {
                const formData = new FormData();
                formData.append('xmlData', xmlText);
                try {
                    const response = await fetch("https://www.tgbornheim.de/v4/?service=mg_anmeldung", {
                        method: 'POST',
                        body: formData, // Use the FormData object as the request body
                    });

                    if (response.ok) {
                        // Request was successful, you can now work with the response data
                        const data = await response.json();
                        console.log("result", data);
                        if (data.success) {
                            setFinished(true);
                        } else {
                            if (data.result) {
                                setError([data.result]);
                            } else if (data.error) {
                                setError([data.error]);
                            } else {
                                setError(["unbekannter fehler beim absenden"]);
                            }
                        }
                    } else {
                        // Handle errors or non-2xx HTTP responses
                        console.error('Request failed with status:', response.status);
                        setError(["request failed"]);
                    }
                } catch (error) {
                    // Handle any network errors or exceptions
                    console.error('Request failed:', error);
                    setError(["request failed"]);
                }
            })().catch((e) => {
                console.error("error", e);
                setError(["request konnte nicht abgesendet werden"]);
            })
        }
    };
    return (
        <div className="AnmeldeForm">
            {
                !finished && <>
                    <h1>Anmeldung</h1>

                    <table className={"layoutTable"}>
                        <tbody>
                        <tr>
                            <td className={"name"}>Gutscheincode:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"gutschein"} state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Titel:</td>
                            <td className={"value"}>
                                <SelectWithErrorContainer name="titel"
                                                          state={state}>
                                    <option></option>
                                    <option>Dr.</option>
                                    <option>Prof.</option>
                                    <option>Prof. Dr.</option>
                                </SelectWithErrorContainer>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Vorname:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"vorname"} state={state} autoComplete={"given-name"}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Nachname:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"nachname"} autoComplete={"family-name"} state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Geburtstag:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer type={"date"} name={"geburtstag"} autoComplete={"bday"}
                                                         state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>E-Mail:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer type={"email"} name={"email"} autoComplete={"email"}
                                                         state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Telefon:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer type={"tel"} name={"telefon"} autoComplete={"tel"} state={state}/>
                            </td>
                        </tr>

                        <tr>
                            <td className={"name"}>Geschlecht:</td>
                            <td className={"value"}>
                                <SelectWithErrorContainer name={"geschlecht"} autoComplete={"sex"} state={state}>
                                    <option value="">Bitte auswählen</option>
                                    <option value="M">Männlich</option>
                                    <option value="W">Weiblich</option>
                                    <option value="D">Divers</option>
                                </SelectWithErrorContainer>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Straße & Hausnr.:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"strasse"} autoComplete={"street-address"} state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>PLZ:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"plz"} autoComplete={"postal-code"} state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>Ort:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"ort"} autoComplete={"address-level2"} state={state}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className={"familyTd"}>
                                <h3>SEPA-Lastschriftmandat</h3>
                                <label className="container">Fremdzahler
                                    <input type="checkbox" checked={fremdzahler}
                                           onChange={(v) => {
                                               setFremdzahler(v.target.checked);
                                           }}/>
                                    <span className="checkmark"></span>
                                </label>

                                {
                                    /*
                                <div className={"flex"}>
                                    <input id="family" type="checkbox"/><label htmlFor="family">Familienmitgliedschaft</label>
                                </div>
                                     */
                                }
                            </td>
                        </tr>
                        {
                            fremdzahler && <>
                                <tr>
                                    <td className={"name"}>Fremdzahler Vorname:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"fzVorname"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Fremdzahler Nachname:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"fzNachname"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Fremdzahler Geschlecht:</td>
                                    <td className={"value"}>
                                        <SelectWithErrorContainer name={"fzGeschlecht"} autoComplete={"sex"}
                                                                  state={state}>
                                            <option value="">Bitte auswählen</option>
                                            <option value="M">Männlich</option>
                                            <option value="W">Weiblich</option>
                                            <option value="D">Divers</option>
                                        </SelectWithErrorContainer>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Fremdzahler Straße & Hausnr.:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"fzStrasse"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Fremdzahler PLZ:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"fzPlz"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Fremdzahler Ort:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"fzOrt"} state={state}/>
                                    </td>
                                </tr>

                            </>
                        }
                        <tr>
                            <td className={"name"}>IBAN:</td>
                            <td className={"value"}>
                                <InputWithErrorContainer name={"iban"} autoComplete={"bank-account-number"} state={state}/>
                            </td>
                        </tr>
                        {/*<!--
                <tr>
                    <td className={"name"}>BIC:</td>
                    <td className={"value"}>
                        <input type={"text"} id={"bic"} name={"bic"} value={bic} onChange={(e) => {
                            setBic(e.target.value)
                        }} autoComplete={"bic"}/>
                    </td>
                </tr>
                <tr>
                    <td className={"name"}>Mandatreferenz:</td>
                    <td className={"value"}>
                        <input type={"text"} id={"mandate"} name={"mandate"} value={mandate} onChange={(e) => {
                            setMandate(e.target.value)
                        }} autoComplete={"off"}/>
                    </td>
                </tr>-->*/}
                        <tr>
                            <td colSpan={2}>
                                <h3>Abteilungen</h3>
                            </td>
                        </tr>
                        <tr>
                            <td className={"name"}>
                                Abteilungen wählen
                            </td>
                            <td>
                                <div className={"abtSelect"}>
                                    <Multiselect
                                        dataKey='name'
                                        textField='name'
                                        data={abteilungen}
                                        value={abt}
                                        onChange={(value) => {
                                            console.log("value", value);
                                            setAbt((oldValue) => {
                                                calculateAbtChange(value);
                                                return value;
                                            });
                                        }}
                                    />
                                    <div className={"selectedAbt"}>
                                        {abt && abt.map((m) => <>
                                            <div className={"abtEntry"}>
                                                <div className={"abtName"}> {m.sparte}</div>
                                                {
                                                    m.price && (!m.priceFamily || !familyMode) &&
                                                    <div className={"abtPrice"}> {getNormalAgeChangedPrice(m,geburtstag)} €</div>
                                                }
                                                {
                                                    m.priceFamily && familyMode &&
                                                    <div className={"abtPrice"}>ganze Familie: {m.priceFamily} €</div>
                                                }
                                            </div>
                                            {
                                                m.priceAufnahme && <>
                                                    <div className={"abtEntry"}>
                                                        <div className={"abtName"}> {m.sparte} Aufnahmegebühr</div>
                                                        <div className={"abtPrice"}> {m.priceAufnahme} €</div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                m.sperr && <>
                                                    <div className={"abtEntry"}>
                                                        <div className={"abtName"}> {m.sparte} &nbsp; hat eine
                                                            Kündigungssperrfrist von 6
                                                            Monaten
                                                        </div>
                                                        <div className={"abtPrice"}>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </>)}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className={"familyTd"}>
                                <h3>Mitgliedsschaft Typ</h3>
                                <label className="container">Einzelmitgliedschaft
                                    <input type="radio" name="radio"
                                           value="einzelmitgliedschaft"
                                           checked={selectedMembership === 'einzelmitgliedschaft'}
                                           onChange={handleMembershipChange}/>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="container">Eltern & Kind
                                    <input type="radio" name="radio"
                                           value="elternkind"
                                           checked={selectedMembership === 'elternkind'}
                                           onChange={handleMembershipChange}/>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="container">Familienmitgliedschaft
                                    <input type="radio" name="radio"
                                           value="familienmitgliedschaft"
                                           checked={selectedMembership === 'familienmitgliedschaft'}
                                           onChange={handleMembershipChange}/>
                                    <span className="checkmark"></span>
                                </label>
                                {
                                    /*
                                <div className={"flex"}>
                                    <input id="family" type="checkbox"/><label htmlFor="family">Familienmitgliedschaft</label>
                                </div>
                                     */
                                }
                            </td>
                        </tr>
                        {
                            (familyMode || familyElternKindMode) && familyRenderId &&
                            familyMembers.map((familyMember, idx) => <>
                                <tr>
                                    <td colSpan={2}>
                                        <h3 className={"familyHeader"}>
                                            <div>
                                                {idx === 0 && familyElternKindMode &&
                                                    <span>Kind unter 18</span>
                                                }
                                                {idx === 0 && familyMode &&
                                                    <span>Familienmitglied {idx + 1}: Partner oder Kind unter 18</span>
                                                }
                                                {idx >= 1 && familyMode &&
                                                    <span>Familienmitglied {idx + 1}: Kind unter 18</span>
                                                }
                                            </div>
                                            {
                                                idx === familyMembers.length - 1 && familyMembers.length > 2 &&
                                                <button
                                                    className={"btn del"}
                                                    onClick={() => {
                                                        familyMembers.splice(idx, 1);
                                                        triggerFamilyRender();
                                                    }}
                                                >Entfernen</button>
                                            }
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Titel:</td>
                                    <td className={"value"}>
                                        <SelectWithErrorContainer name={"titel"} targetOb={familyMember} state={state}>
                                            <option></option>
                                            <option>Dr.</option>
                                            <option>Prof.</option>
                                            <option>Prof. Dr.</option>
                                        </SelectWithErrorContainer>
                                        {
                                            /*
                                            <select
                                            type={"text"}
                                            name={"titel"}
                                            value={familyMember.titel}
                                            onChange={(e) => {
                                                familyMember.titel = e.target.value;
                                                triggerFamilyRender();
                                            }}
                                        >
                                            <option></option>
                                            <option>Dr.</option>
                                            <option>Prof.</option>
                                            <option>Prof. Dr.</option>
                                        </select>
                                             */
                                        }

                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Vorname:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"vorname"} targetOb={familyMember}
                                                                 autoComplete={"give-name"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Nachname:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer name={"nachname"} targetOb={familyMember}
                                                                 autoComplete={"family-name"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Geschlecht:</td>
                                    <td className={"value"}>
                                        <SelectWithErrorContainer name={"geschlecht"} targetOb={familyMember}
                                                                  autoComplete={"sex"}
                                                                  state={state}>
                                            <option value="">Bitte auswählen</option>
                                            <option value="M">Männlich</option>
                                            <option value="W">Weiblich</option>
                                            <option value="D">Divers</option>
                                        </SelectWithErrorContainer>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Geburtstag:</td>
                                    <td className={"value"}>
                                        <InputWithErrorContainer type={"date"} name={"geburtstag"} targetOb={familyMember}
                                                                 autoComplete={"bday"} state={state}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"name"}>Abteilungen</td>
                                    <td>
                                        <div className={"abtSelect"}>
                                            <Multiselect
                                                dataKey='name'
                                                textField='name'
                                                data={abteilungen}
                                                value={familyMember.abt}
                                                onChange={(value) => {
                                                    console.log("value", value);
                                                    calculateAbtChange(value);
                                                    familyMember.abt = value;
                                                    triggerFamilyRender();
                                                }}
                                            />
                                            <div className={"selectedAbt"}>
                                                {familyMember.abt && familyMember.abt.map((m) => <>
                                                    <div
                                                        className={"abtEntry"}>
                                                        <div className={"abtName"}> {m.name}</div>
                                                        {
                                                            m.price && (!m.priceFamily || !familyMode) &&
                                                            <div className={"abtPrice"}> {getNormalAgeChangedPrice(m,familyMember.geburtstag)} €</div>
                                                        }
                                                        {
                                                            m.priceFamily && familyMode &&
                                                            <div className={"abtPrice"}>ganze
                                                                Familie: {m.priceFamily} €</div>
                                                        }

                                                    </div>
                                                    {
                                                        m.priceAufnahme && <>
                                                            <div className={"abtEntry"}>
                                                                <div className={"abtName"}> {m.sparte} Aufnahmegebühr</div>
                                                                <div className={"abtPrice"}> {m.priceAufnahme} €</div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        m.sperr && <>
                                                            <div className={"abtEntry"}>
                                                                <div className={"abtName"}> {m.sparte} </div>
                                                                <div className={"abtPrice"}> hat eine Kündigungssperrfrist von 6
                                                                    Monaten
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </>)

                        }
                        <tr>
                            {
                                familyMode && familyRenderId &&
                                <td colSpan={4}>
                                    <button className={"btn"} onClick={() => {
                                        familyMembers.push({});
                                        triggerFamilyRender();
                                    }}>weiteres Familienmitglied hinzufügen
                                    </button>
                                </td>
                            }
                        </tr>
                        </tbody>
                    </table>
                    <table className={"layoutTable sumTable"} cellSpacing={0} cellPadding={0}>
                        <tbody>

                        <tr>
                            <td className={"noBorder noPadding"} colSpan={4}>
                                <h3>Gebühren</h3>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>Menge</th>
                            <th>Stückpreis</th>
                            <th>Preis</th>
                        </tr>
                        {
                            expectedPrices.map((entry) => <tr>
                                <td>{entry.name}</td>
                                <td>{entry.amount}</td>
                                <td>
                                    {entry.amount && <span>
                                {entry.price} €
                            </span>}
                                </td>
                                <td className={"priceCol"}>
                                    {
                                        !entry.monthly && <span>
                                Einmalig &nbsp;
                                </span>
                                    }
                                    {
                                        entry.priceType && <span>
                                {entry.priceType} &nbsp;
                                </span>
                                    }
                                    {entry.sum} €

                                </td>
                            </tr>)
                        }

                        <tr className={"sumrow"}>
                            <td className={"noBorder"}></td>
                            <td className={"noBorder"}></td>
                            <td className={"noBorder"}></td>
                            <td className={"priceCol"}> {firstBill} €<div className={"priceColInfo"}>erste Abbuchung</div></td>
                        </tr>
                        <tr className={"sumrow"}>
                            <td>Summe</td>
                            <td></td>
                            <td></td>
                            <td className={"priceCol"}>{overallSum*3} €<div className={"priceColInfo"}>quartalsweise Abbuchung (ab nächstem Quartal)</div> </td>
                        </tr>
                        {
                            specialOverallSumsList.map((priceType) => <tr className={"sumrow"}>
                                <td className={"noBorder"}></td>
                                <td className={"noBorder"}></td>
                                <td className={"noBorder"}></td>
                                <td className={"priceCol"}>{priceType} {specialOverallSums[priceType]} €</td>
                            </tr>)
                        }
                        </tbody>
                    </table>
                    {
                        (state.getValue("gutschein")?.length>0)&&
                        <div className={"gutscheinActive"}>Aufgrund der Angabe eines Gutscheincodes wird sich die erste Abbuchung entsprechend der Konditionen verringern, wenn der Gutscheincode gültig ist.</div>
                    }
                    <h3>Anmelden</h3>

                    <div>

                        <label className=""><h4>Wie sind sie auf uns aufmerksam geworden?</h4>
                            <select
                                type={"text"}
                                name={"erfahren"}
                                value={erfahren}
                                onChange={(e) => setErfahren(e.target.value)}
                            >
                                <option></option>
                                <option value={"Bekannte"}>Bekannte</option>
                                <option value={"Facebook"}>Facebook</option>
                                <option value={"Flyer"}>Flyer</option>
                                <option value={"Instagram"}>Instagram</option>
                                <option value={"Plakate"}>Plakate</option>
                                <option value={"U-Bahn-Plakate"}>U-Bahn-Plakate</option>
                                <option value={"U-Bahn-Swingcards"}>U-Bahn-Swingcards</option>
                                <option value={"Vereinshomepage/Internet"}>Vereinshomepage / Internet</option>
                                <option value={"Zeitung"}>Zeitung</option>
                            </select>
                        </label>
                    </div>
                    <div>

                        <label className="container"><h4>SEPA Lastschriftmandat erteilen</h4>
                            {/*<div className={"smallInfo"}>Hiermit ermächtige ich die Turngemeinde Bornheim 1860 e.V.
                                Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein
                                Kreditinstitut an, die von der Turngemeinde Bornheim 1860 e.V. gezogenen Lastschriften
                                einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum,
                                die Erstattung des belasteten Betrages verlangen. Es gelten die mit meinem Kreditinstitut
                                vereinbarten Bedingungen. Der Mitgliedsbeitrag wird jeweils vierteljährlich im Voraus zum
                                5.Tag des ersten Monats des Quartals abgebucht.
                            </div>*/}
                            <input type="checkbox" checked={acceptSepa}
                                   onChange={(v) => {
                                       setAcceptSepa(v.target.checked);
                                   }}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div>
                        <label className="container"><a target={"_blank"}
                                                        href={"https://cms.tgbornheim.de/wp-content/uploads/2022/08/Satzung-ab-20.05.2022-2.pdf"}>Vereinssatzung</a> zustimmen
                            <input type="checkbox" checked={acceptSatzung}
                                   onChange={(v) => {
                                       setAcceptSatzung(v.target.checked);
                                   }}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div>
                        <label className="container"><a target={"_blank"}
                                                        href={"https://www.tgbornheim.de/datenschutz/datenschutz.pdf"}>Datenschutzbestimmungen</a> zustimmen
                            <input type="checkbox" checked={acceptDatenschutz}
                                   onChange={(v) => {
                                       setAcceptDatenschutz(v.target.checked);
                                   }}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    {
                        isMainKind &&
                        <div>
                            <label className="container">Der Erziehungsberechtigte hat der Anmeldung zugestimmt
                                <input type="checkbox" checked={acceptErziehung}
                                       onChange={(v) => {
                                           setAcceptErziehung(v.target.checked);
                                       }}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    }

                    {
                        fitnessWarning &&
                        <div className={"warning"}>
                            Vielen Dank für die Anmeldung. Jugendliche unter 18 Jahren müssen sich einen Trainertermin unter
                            069/94507215 ausmachen.
                        </div>
                    }
                    {
                        error?.length > 0 && <div className={"error"}>
                            {error.map((e) => <div>
                                {e}
                            </div>)
                            }
                        </div>
                    }
                    <button className={"anmeldeButton"} onClick={anmeldenClick}>Anmeldung abschicken</button>
                </>
            }
            {
                finished && <>
                    <h3>Anmeldung wurde abgesendet</h3>
                </>
            }

        </div>);
}